/* 
 * @Author: 曹俊杰 
 * @Date: 2023-02-17 06:02:52
 * @Module: 答题卡
 */
 <template>
  <div class="answer">
    <el-dialog :title="`章节测试（${answerIndex+1}/${list.length}）`" v-if="dialogVisible" :visible.sync="dialogVisible" width="500px" @closed="closed" v-loading="!list.length">
      <div v-if="list.length">
        <!-- 单选 -->
        <div v-if="list[answerIndex].questionType==0">
          <div class="top">
            {{list[answerIndex].content}}（单选题）
          </div>
          <!-- <div class="choices">
            <span v-for="(item,index) in list[answerIndex].choices" :key="index">
              {{index|order}}
              {{item}}
              &nbsp;
            </span>
          </div> -->
          <div class="choose">
            <el-row>
              <el-col :span="24" v-for="(item,index) in list[answerIndex].choices" :key="index" style="height:30px;">
                <el-radio v-model="choose0" :label="index|order">{{index|order}}、{{item}}</el-radio>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 多选 -->
        <div v-if="list[answerIndex].questionType==1">
          <div class="top">
            {{list[answerIndex].content}}（多选题）
          </div>
          <!-- <div class="choices">
            <span v-for="(item,index) in list[answerIndex].choices" :key="index">
              {{index|order}}
              {{item}}
              &nbsp;
            </span>
          </div> -->
          <div class="choose">
            <el-row>
              <el-col :span="24" v-for="(item,index) in list[answerIndex].choices" :key="index" style="height:30px;">
                <el-checkbox v-model="choose1" :label="index|order">{{index|order}}、{{item}}</el-checkbox>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 判断 -->
        <div v-if="list[answerIndex].questionType==2">
          <div class="top">
            {{list[answerIndex].content}}（判断题）
          </div>
          <div class="choices">
            <span v-for="(item,index) in list[answerIndex].choices" :key="index">
              {{index|order}}
              {{item}}
              &nbsp;
            </span>
          </div>
          <div class="choose">
            <el-radio v-model="choose2" label="正确">正确</el-radio>
            <el-radio v-model="choose2" label="错误">错误</el-radio>
          </div>
        </div>
        <!-- 填空 -->
        <div v-if="list[answerIndex].questionType==3">
          <div class="top">
            {{list[answerIndex].content}}（填空题）
          </div>
          <div class="choose">
            <el-row>
              <el-col :span="24" v-for="(item,index) in list[answerIndex].choices" :key="index" style="height:30px;">
                <el-input style="width:100%;margin-bottom:16px" v-model="choose3[index]" placeholder="请输入内容" size="mini">
                </el-input>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {
  },
  data () {
    return {
      dialogVisible: false,
      answerIndex: 0,
      choose0: null,
      choose1: [],
      choose2: null,
      choose3: [],
      list: []
    };
  },
  computed: {
    ...mapState({
      courseDetail: state => state.course.courseDetail
    })
  },
  mounted () { },
  filters: {
    order (nub) {
      if (nub === 0) {
        return "A"
      } else if (nub === 1) {
        return "B"
      } else if (nub === 2) {
        return "C"
      } else if (nub === 3) {
        return "D"
      } else if (nub === 4) {
        return "E"
      } else if (nub === 5) {
        return "F"
      }else{
				return "G"
			}
    }
  },
  methods: {
    async getQuestion () {
      const typeSwitch = (list) =>
        list.map(item => {
          if (item.questionType == 0) {
            return {
              ...item,
              choices: item.choices.split("|")
            }
          } else if (item.questionType == 1) {
            return {
              ...item,
              choices: item.choices.split("|")
            }
          } else if (item.questionType == 3) {
            let choices = parseInt(item.choices);
            if (typeof (choices) !== "number") {
              choices = 0
            }

            return {
              ...item,
              choices
            }
          } else {
            return item
          }
        })
      const { lessonUuid } = this.$route.params
      const { status, data, info } = await this.$api.getQuestion({ lessonUuid });
				console.log('data.length',data.length)
				console.log('status',status)
      if (status === 200) {
        this.list = typeSwitch(data)
				console.log('data.length',data.length)
        return new Promise((resolve, reject) => {
          if (data.length) {
            resolve()
          } else {
            reject()
          }
        })
      } else {
        this.$message({
          message: info,
          type: 'error'
        })
      }
    },
    show () {
      this.getQuestion().then(() => {
        this.dialogVisible = true;
      }).catch((res) => {
				console.log(res)
        this.$message("此章节没有试题，请联系管理员")
      })
    },
    closed () {
      this.answerIndex = 0;
      this.choose0 = null;
      this.choose1 = [];
      this.choose2 = null;
      this.choose3 = [];
    },
    async submit () {
      const typeSwitch = (questionType) => {
        if (questionType == 1) {
          return this['choose' + questionType].join("|")
        } else if (questionType == 3) {
          return this['choose' + questionType].join("|")
        } else {
          return this['choose' + questionType]
        }
      }
      const daan = this.list[this.answerIndex];
      const { status, data, info } = await this.$api.postQuestionAnswer({
        questionUuid: daan.uuid,
        answer: typeSwitch(daan.questionType)
      })
      if (status == 200) {
        if (data.correct) {
					//  如果是最后一题就显示结果
          if ((this.answerIndex + 1) >= this.list.length) {
            const index = this.courseDetail.lessons.findIndex(item => {
              item.uuid === this.$route.params.lessonUuid
            })
						//  如果不是最后一个章节就跳转下一个章节
            if (index + 1 >= this.courseDetail.lessons.length) {
              this.$confirm('此章节测试通过，是否继续学习下一章节?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
              }).then(() => {
                this.$emit("answersuccess");
                this.dialogVisible = false;
              })
            } else {
              this.$confirm('恭喜您此课程已全部通过测试', '提示', {
								showClose:false,
								showCancelButton:false,
								closeOnClickModal:false,
                confirmButtonText: '确定',
                type: 'success'
              }).then(() => {
                this.$emit("answersuccess");
                this.dialogVisible = false;
              })
            }

          } else {
            this.answerIndex = this.answerIndex + 1
          }
        } else {
          this.$message({
            type: "warning",
            message: '答案错误'
          })
          this.closed()
        }
      } else {
        this.$message({
          message: info,
          type: "error"
        })
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.answer {
  &/deep/.el-dialog {
    &__header {
      padding: 20px;
      border-bottom: 1px solid #eeeeee;
    }
    &__body {
      padding-top: 10px;
    }
    &__footer {
      border-top: 1px solid #eeeeee;
      padding-top: 20px;
    }
  }

  .top {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    color: #000000;
  }
  .choices {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
    margin-top: 16px;
  }
  .choose {
    margin-top: 16px;
  }
}
</style>