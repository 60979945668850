/* 
 * @Author: 曹俊杰 
 * @Date: 2023-02-17 03:02:45
 * @Module:课程视频 
 */
 <template>
  <div class="CourseVideo">

    <div>
      <el-card shadow="never" :body-style="{position:'relative'}">
        <div class="CourseVideo-top">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/course' }">课程中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{courseDetail.name}}</el-breadcrumb-item>
          </el-breadcrumb>
          <p v-if="false" class="pointer btn">开始答题</p>
        </div>

        <el-row :gutter="20" style="margin-top:20px;">
          <el-col :span="16">
            <div class="box">
              <video :src="this.$oss+courseDetail.lessons[courseIndex].url" controls @ended="onended"></video>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="right">
              <div class="top">视频选集（{{courseIndex+1}}/{{courseDetail.lessons.length}}）</div>
              <el-row>
                <el-col :span="24">
                  <div class="headline">
                    <div class="item" :class="{
											active:courseIndex==index,
'not-allowed':index>courseDetail.passedLesson
											}" v-for="(item,index) in courseDetail.lessons" :key="item.uuid">
                      <div class="item-l  pointer" @click="itemClick(item.uuid)">
                        <span> P{{item.number}} {{item.title}}</span>
                        <i class="el-icon-check" style="margin-left:6px" v-if="courseDetail.passedLesson>index"></i>
                      </div>
                      <div class="item-r">
                        <el-button type="text" size="mini" v-show="(courseDetail.passedLesson>index)||(kanwan==index)" @click="zuoti(item.uuid)">重新做题</el-button>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <answer ref="answer" @answersuccess="answerSuccess" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import answer from "./components/answer"
export default {
  components: { answer },
  data () {
    return {
      kanwan: 0
    };
  },
  computed: {
    ...mapState({
      courseDetail: state => state.course.courseDetail
    }),
    courseIndex () {
      return this.courseDetail.lessons.findIndex(item => item.uuid === this.$route.params.lessonUuid)
    }
  },
  mounted () { },
  methods: {
    onended () {
      this.$refs.answer.show();
      this.kanwan = this.courseIndex
    },
    itemClick (lessonUuid, callback) {
      const { passedLesson, lessons } = this.courseDetail
      const toIndex = lessons.findIndex(item => item.uuid === lessonUuid);
      if (passedLesson >= toIndex) {
        this.switchCourse(lessonUuid);
        callback && callback()
      }
    },
    zuoti (lessonUuid) {
      this.itemClick(lessonUuid, () => {
        this.$refs.answer.show()
      })
    },
    answerSuccess () {
      if (this.courseIndex == this.courseDetail.passedLesson) {
        //  手动更新已完成章节
        this.$store.dispatch("course/setPassedLesson", this.courseDetail.passedLesson + 1);
      }

      // 如果有下一章节就跳转下一章节
      if ((this.courseIndex + 1) < this.courseDetail.lessons.length) {
        this.switchCourse(this.courseDetail.lessons[this.courseIndex + 1].uuid);
      }
    },
    switchCourse (lessonUuid) {
      const { params } = this.$route
      if (lessonUuid) {
        this.$router.push({ path: `/course/${params.uuid}/${lessonUuid}` });
      }
    }
  },
  watch: {
    "$route.params": {
      handler (params) {
        console.log(12, params)
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
.CourseVideo {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      color: #0062ff;
      font-size: 14px;
    }
  }
  .box {
    background: #000;
    padding-left: 100%;
    padding-top: 56.25%;
    position: relative;
  }
  video {
    width: 100%;
    // padding: 0 24px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .content {
    padding-top: 20px;
    h1 {
      color: #0c0d0f;
      line-height: 33px;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
      line-height: 20px;
      color: #606866;
      margin-bottom: 10px;
    }
  }
  .right {
    .top {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      color: #000000;
    }
    .headline {
      padding: 16px 0;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      /* identical to box height */
      color: #262626;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          color: #00c152;
        }
        &-r {
          &/deep/.el-button {
            color: rgb(0, 161, 264);
          }
          &/deep/.is-disabled {
            color: #c0c4cc;
          }
        }
      }
      .active {
        color: rgb(0, 161, 264);
        background-image: url(~@/static/image/playing.gif);
        background-repeat: no-repeat;
        background-size: 14px;
        padding-left: 20px;
        background-position: left center;
      }
    }
  }
}
</style>